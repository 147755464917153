<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text">
            <hb-header :divider="false">
                <hb-page-header title="Media Viewers">
                    <template v-slot:description>
                        <div>
                            <div>import { HbMediaViewer }  from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-tabs v-model="activeTab">
            <v-tab
                :ripple="false"
            >
                Prototype
            </v-tab>
            <!--
            <v-tab
                :ripple="false"
            >
                Examples
            </v-tab>
            -->
            <v-tab
                :ripple="false"
            >
                Props, Slots &amp; Events
            </v-tab>

        </hb-tabs>

        <v-divider></v-divider>

        <div v-if="activeTab === 0" class="pt-1">

            <v-row no-gutters>
                <v-col cols="6" class="pt-3 pr-6">
                    <hb-card no-title>
                        <hb-media-viewer
                            v-model="mediaViewer"
                            :grid="grid"
                            :disable-grid-click="disableGridClick"
                            :uploader="uploader"
                            :upload-error="uploadError"
                            :accept="accept"
                            :hide-options="hideOptions"
                            :disable-delete="disableDelete"
                            :hide-delete="hideDelete"
                            @upload="handleUploadedFiles"
                            @delete="handleDeleteFile"
                        >

                            <template v-slot:slides>
                                <slide
                                    v-for="(upload, i) in uploads"
                                    :key="'upload_' + i"
                                    :id="'temp_upload_id_' + i"
                                    :src="upload.src"
                                />
                                <slide
                                    v-for="(slide, i) in slides"
                                    :key="'slide_' + i"
                                    :id="slide.id"
                                    :video="slide.video"
                                    :video_src="slide.video_src"
                                    :src="slide.src"
                                    :thumbnail="slide.thumbnail"
                                />
                            </template>
                        </hb-media-viewer>
                    </hb-card>

                    <hb-card no-title>
                        <hb-form label="Open Media Viewer Manually">
                            <div class="mt-2">
                                <HbBtn small @click="mediaViewer = true">Open</HbBtn>
                            </div>
                        </hb-form>
                    </hb-card>

                </v-col>

                <v-col cols="6" class="mt-3">

                    <hb-card title="Props">

                        <hb-form label="grid">
                            <HbSwitch
                                v-model="grid"
                                :label="grid ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form label="disable-grid-click">
                            <HbSwitch
                                v-model="disableGridClick"
                                :label="disableGridClick ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form label="uploader">
                            <HbSwitch
                                v-model="uploader"
                                :label="uploader ? 'true' : 'false/null'"
                                :disabled="!grid"
                            />
                        </hb-form>

                        <hb-form label="upload-error">
                            <HbSwitch
                                v-model="uploadError"
                                :label="uploadError ? 'true' : 'false/null'"
                                :disabled="!grid || !uploader"
                            />
                        </hb-form>

                        <hb-form label="accept">
                            <HbTextField
                                v-model="accept"
                                placeholder="Enter Accepted File Type(s)"
                                :disabled="!grid || !uploader"
                            />
                        </hb-form>

                        <hb-form label="hide-options">
                            <HbSwitch
                                v-model="hideOptions"
                                :label="hideOptions ? 'true' : 'false/null'"
                                :disabled="!grid || !uploader"
                            />
                        </hb-form>

                        <hb-form label="disable-delete">
                            <HbSwitch
                                v-model="disableDelete"
                                :label="disableDelete ? 'true' : 'false/null'"
                                :disabled="hideOptions"
                            />
                        </hb-form>

                        <hb-form label="hide-delete">
                            <HbSwitch
                                v-model="hideDelete"
                                :label="hideDelete ? 'true' : 'false/null'"
                                :disabled="hideOptions"
                            />
                        </hb-form>

                    </hb-card>

                </v-col>

            </v-row>

            <v-row no-gutters>
                <v-col cols="12" class="ma-0 pa-0">
                    <hb-card color="#E0F5F5" title="Code + Data" class="mt-3">
                        <pre class="px-4" :style="prototypeCodeExpanded ? 'height:auto' : 'height:479px;'">
                            <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="prototypeCodeExpanded = !prototypeCodeExpanded">
                                {{ prototypeCodeExpanded ? 'Collapse Code' : 'Expand Code' }}
                                <HbIcon class="pl-1" :mdi-code="prototypeCodeExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
                            </div>
&lt;hb-media-viewer
    v-model="mediaViewer"<span v-if="grid"><br />&nbsp;&nbsp;&nbsp;&nbsp;grid</span><span v-if="disableGridClick"><br />&nbsp;&nbsp;&nbsp;&nbsp;disable-grid-click</span><span v-if="uploader"><br />&nbsp;&nbsp;&nbsp;&nbsp;uploader</span><span v-if="uploadError"><br />&nbsp;&nbsp;&nbsp;&nbsp;upload-error</span><span v-if="accept !== 'image/png, image/jpeg'"><br />&nbsp;&nbsp;&nbsp;&nbsp;:accept="{{ accept }}"</span><span v-if="hideOptions"><br />&nbsp;&nbsp;&nbsp;&nbsp;hideOptions</span><span v-if="disableDelete"><br />&nbsp;&nbsp;&nbsp;&nbsp;disableDelete</span><span v-if="hideDelete"><br />&nbsp;&nbsp;&nbsp;&nbsp;hideDelete</span>
    @upload="handleUploadedFiles"
    @delete="handleDeleteFile"
>

    &lt;template v-slot:slides>
        &lt;slide
            v-for="(upload, i) in uploads"
            :key="'upload_' + i"
            :id="'temp_upload_id_' + i"
            :src="upload.src"
        />
        &lt;slide
            v-for="(slide, i) in slides"
            :key="'slide_' + i"
            :id="slide.id"
            :video="slide.video"
            :video_src="slide.video_src"
            :src="slide.src"
            :thumbnail="slide.thumbnail"
        />
    &lt;/template>
&lt;/hb-media-viewer>

uploads: {{ uploads }}

slides: {{ slides }}

handleUploadedFiles(files){
    Array.from(files).forEach(file => {
        this.uploadError = false;
        if(file.size > 10000000){
            this.uploadError = true;
            this.globalNotificationList.push('Failed: "' + file.name + '" is larger than the allowed maximum file size of 10 MB.');
            this.globalNotification = true;
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
            this.uploads.unshift({
                src: e.target.result,
                file: file,
            });
            };
            reader.readAsDataURL(file);
        }
    });
}
                        </pre>
                    </hb-card>
                </v-col>
            </v-row>

        </div>

        <!--
        <div v-if="activeTab === 1">

            <hb-card class="mt-4 mb-6">
                <template v-slot:title>
                    Examples + Codes
                </template>

                <hb-form label="Open Media Viewer Manually">
                    <div class="mt-2">
                        <HbBtn small @click="mediaViewer = true">Open</HbBtn>
                    </div>
                </hb-form>

                <hb-media-viewer
                    v-model="mediaViewer"
                    grid
                    uploader
                    :upload-error="uploadError"
                    @upload="handleUploadedFiles"
                >

                    <template v-slot:slides>
                        <slide
                            v-for="(upload, i) in uploads"
                            :key="'upload_' + i"
                            :id="'temp_upload_id_' + i"
                            :src="upload.src"
                        />
                        <slide
                            v-for="(slide, i) in slides"
                            :key="'slide_' + i"
                            :id="slide.id"
                            :video="slide.video"
                            :video_src="slide.video_src"
                            :src="slide.src"
                            :thumbnail="slide.thumbnail"
                        />
                    </template>
                </hb-media-viewer>

                <hb-card class="pa-6" color="#E0F5F5" no-title>
    <pre>
    &lt;hb-media-viewer
        v-model="mediaViewer"
        grid
        uploader
        @upload="handleUploadedFiles"
    >
        &lt;template v-slot:slides>
            &lt;slide
                v-for="(upload, i) in uploads"
                :key="'upload_' + i"
                :id="'temp_upload_id_' + i"
                :src="upload.src"
            />
            &lt;slide
                v-for="(slide, i) in slides"
                :key="'slide_' + i"
                :id="slide.id"
                :video="slide.video"
                :src="slide.src"
                :thumbnail="slide.thumbnail"
            />
        &lt;/template>
    &lt;/hb-media-viewer>

    uploads: {{ uploads }}

    slides: {{ slides }}

    handleUploadedFiles(files){
        Array.from(files).forEach(file => {
            this.uploadError = false;
            if(file.size > 10000000){
                this.uploadError = true;
                this.globalNotificationList.push('Failed: "' + file.name + '" is larger than the allowed maximum file size of 10 MB.');
                this.globalNotification = true;
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.uploads.unshift({
                    src: e.target.result,
                    file: file,
                });
                };
                reader.readAsDataURL(file);
            }
        });
    }
    </pre>
                </hb-card>
            </hb-card>

        </div>
        -->

        <hb-card title="Props" class="pb-1" :class="activeTab === 1 ? 'mt-4 mb-6' : 'my-6'">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="globalNotification"
            :type="globalNotificationType"
            :description="globalNotificationDescription"
            :list="globalNotificationList"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemMediaViewer",
        data: function() {
            return {
                activeTab: '',
                prototypeCodeExpanded: false,
                grid: true,
                disableGridClick: false,
                uploader: true,
                uploadError: false,
                mediaViewer: false,
                accept: 'image/png, image/jpeg',
                hideOptions: false,
                disableDelete: false,
                hideDelete: false,
                slides: [
                    {
                        id: '0123456',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '1234567',
                        video: true,
                        video_src: 'https://videos.pexels.com/video-files/4772983/4772983-sd_360_640_24fps.mp4',
                        src: 'https://images.pexels.com/videos/4772983/pexels-photo-4772983.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                        thumbnail: '',
                    },
                    {
                        id: '2345678',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '3456789',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '4567890',
                        video: true,
                        video_src: 'https://videos.pexels.com/video-files/4874384/4874384-sd_640_360_30fps.mp4',
                        src: 'https://images.pexels.com/videos/4874384/4k-8-alberi-animale-4874384.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                        thumbnail: '',
                    },
                    {
                        id: '5678901',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                        thumbnail: '',
                    },
                    /*
                    {
                        id: '012345a',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '123456a',
                        video: true,
                        src: 'https://videos.pexels.com/video-files/4772983/4772983-sd_360_640_24fps.mp4',
                        thumbnail: 'https://images.pexels.com/videos/4772983/pexels-photo-4772983.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                    },
                    {
                        id: '234567a',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '345678a',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '456789a',
                        video: true,
                        src: 'https://videos.pexels.com/video-files/4874384/4874384-sd_640_360_30fps.mp4',
                        thumbnail: 'https://images.pexels.com/videos/4874384/4k-8-alberi-animale-4874384.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                    },
                    {
                        id: '567890a',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                        thumbnail: '',
                    }
                    */
                ],
                uploads: [],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'grid', type: 'boolean', default: 'false', description: 'If set to true, displays the grid view of the slides.' },
                    { name: 'disable-grid-click', type: 'boolean', default: 'false', description: 'If set to true, disables the grid being clickable and disables opening the gallery viewer on click.' },
                    { name: 'uploader', type: 'boolean', default: 'false', description: 'If set to true, enables the uploader for grid view.' },
                    { name: 'upload-error', type: 'boolean', default: 'false', description: 'If set to true, puts the optional grid view uploader into a visual error state.' },
                    { name: 'accept', type: 'string', default: 'image/png, image/jpeg', description: 'Overrides the default accepted file types for the optional grid view uploader.' },
                    { name: 'hide-options', type: 'boolean', default: 'false', description: 'If set to true, hides the more actions menu icon.' },
                    { name: 'disable-delete', type: 'boolean', default: 'false', description: 'If set to true, puts the "Delete Media" option in the more actions menu in a disabled state.' },
                    { name: 'hide-delete', type: 'boolean', default: 'false', description: 'If set to true, hides the "Delete Media" option in the more actions menu.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'slides', description: 'Use this slot to pass in slides which are shared for grid view, gallery view, and gallery thumbnail track displays.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@close', description: 'Event for when close icon is clicked in gallery view mode.' },
                    { name: '@grid-click', description: 'Event for when a grid item is clicked in grid view mode. Payload is the data of the grid selection that was clicked.' },
                    { name: '@upload', description: 'Event for when user has added items for upload. Payload is the uploaded files.' },
                    { name: '@delete', description: 'Event for when user has clicked the "Delete Media" option in the more options menu. Payload is the data of the current slide.' },
                ],
                description: '',
                globalNotification: false,
                globalNotificationType: '',
                globalNotificationDescription: '',
                globalNotificationList: [],
                // goToId: '',
            };
        },
        methods: {
            handleUploadedFiles(files){
                this.uploadError = false;
                Array.from(files).forEach(file => {
                    if(file.size > 10000000){
                        this.uploadError = true;
                        this.globalNotificationList.push('Failed: "' + file.name + '" is larger than the allowed maximum file size of 10 MB.');
                        this.globalNotificationType = 'error';
                        this.globalNotification = true;
                    } else {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                        this.uploads.unshift({
                            src: e.target.result,
                            file: file,
                            /*
                            caption: '',
                            is_thumnail: 0,
                            */
                        });
                        };
                        reader.readAsDataURL(file);
                    }
                });
            },
            handleDeleteFile(payload){
                this.globalNotificationDescription = 'Delete Media was clicked:';
                this.globalNotificationList = [
                    'Event: "@delete"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.globalNotificationType = 'success';
                this.globalNotification = true;
            }
        },
        watch: {
            globalNotification(){
                if(!this.globalNotification){
                    this.globalNotificationList = [];
                }
            }
        }
    }
</script>

<style scoped>

</style>
